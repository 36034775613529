import hyperactiv from 'hyperactiv'
const { observe, computed } = hyperactiv


export default class {
  state = {}
  refs = {}

  constructor(el) {
    this.state = observe(this.initState())
    this.el = el

    // refs setup
    el.querySelectorAll('[data-ref]').forEach((refEl) => {
      let key = refEl.dataset.ref
      const keyMatch = key.match(/^\[(.+)\]$/)
      if (keyMatch) {
        key = keyMatch[1]
        if (!this.refs[key]) {
          this.refs[key] = [refEl]
        } else {
          this.refs[key].push(refEl)
        }
      } else {
        this.refs[key] = refEl
      }
      el.removeAttribute('data-ref')
    })
  }

  computed(fn) {
    return computed(fn)
  }

  initState() {
    return {}
  }

  init() {}

  computed(fn) {
    return computed(fn)
  }
}
