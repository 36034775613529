const sections = new Map
const instances = new WeakSet
const asyncRegisters = []
let containerSelector = '#layout-container'

const domIsReady = async function() {
  if (['complete', 'interactive'].includes(document.readyState)) {
    return Promise.resolve()
  }
  else {
    return new Promise(function(resolve) {
      document.addEventListener('readystatechange', function onReadyStateChange() {
        if (['complete', 'interactive'].includes(document.readyState)) {
          resolve()
          document.removeEventListener('readystatechange', onReadyStateChange)
        }
      })
    })
  }
}

export async function parse(el) {
  await domIsReady()

  const containerEl = el instanceof HTMLElement ?  el : document.querySelector(containerSelector)

  if (containerEl) {
    Array.from(containerEl.querySelectorAll('[data-section]')).forEach((el) => {
      const sectionName = el.dataset.section
      if (sections.has(sectionName)) {
        const sectionFn = sections.get(sectionName)
        sectionCreate(new sectionFn(el))
      }
      else {
        let imported = false
        let index = 0
        while (!imported && asyncRegisters[index]) {
          const importer = asyncRegisters[index](sectionName)
          if (typeof importer === 'object' && importer.constructor === Promise) {
            imported = true
            importer
              .then((module) => {
                if (typeof module?.default === 'function') {
                  sectionCreate(new module.default(el))
                }
                else {
                  throw new Error(`Sections: ${sectionName} module default is not callable.`)
                }
              })
              .catch((err) => {
                throw new Error(err)
              })
          }
          index++
        }
        
        if (!imported) {
          console.warn(`Sections: No importer for section '${sectionName}'`)
        }
      }
    })
  }
  else {
    return Promise.reject('Sections: no container element')
  }
}

export function register(key, section) {
  if (typeof key === 'string' && typeof section === 'function') {
    sections.set(key, section)
    return true
  }
  return false
}

export function registerAsync(fn) {
  if (typeof fn === 'function') {
    asyncRegisters.push(fn)
    return true
  }
  return false
}

export function destroy(instance) {
  if (instances.has(instance)) {
    sectionDestroy(instance)
    return instances.delete(instance)
  }
  return false
}

export function setParam(key, value) {
  if (key === 'containerSelector' && typeof value === 'string') {
    containerSelector = value
    return true
  }
  return false
}

function sectionCreate(instance) {
  instances.add(instance)
  sectionInit(instance)
}

function sectionInit(instance) {
  if (typeof instance?.init === 'function') {
    instance.init.call(instance)
  }
}

function sectionDestroy(instance) {
  if (typeof instance?.destroy === 'function') {
    instance.destroy.call(instance)
  }
}
