import Section from '../modules/section'
import { register } from '../modules/sections'
import { on, emit } from '../modules/events'

class HeaderSection extends Section {
  init() {
    on(this.refs.menu, 'pointerup', () => {
      emit('mobilemenu:toggle')
    })
  }
}

register('header', HeaderSection)
