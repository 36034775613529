import Section from '../modules/section'
import { register } from '../modules/sections'
import { on } from '../modules/events'
import { tween } from 'shifty'

class StudioSection extends Section {
  initState() {
    return {
      index: -1
    }
  }

  init() {
    this.computed(() => this.indexChanged(this.state.index))

    if (this.refs?.toggles?.length) {
      this.refs.toggles.forEach((el) => {
        on(el, 'pointerup', (e) => this.toggle(e))
      })
    }
  }

  toggle(e) {
    const index = this.refs.toggles.indexOf(e.target)
    this.state.index = (index === this.state.index) ? -1 : index
  }

  indexChanged() {
    if (!this.refs?.infos?.length) {
      return
    }

    this.refs.infos.forEach((el, i) => this.toggleInfo(el, i))
  }

  toggleInfo(el, i) {
    const isActive = !!el.style.height
    const tweenParams = {}
    let heightStart = el.offsetHeight
    const toggleEl = this.refs.toggles[i]

    if (isActive || this.state.index !== i) {
      tweenParams.to = { height: 0 }
      toggleEl.classList.remove(toggleEl.dataset.activeClass)
    }
    else {
      toggleEl.classList.add(toggleEl.dataset.activeClass)
      el.style.height = 'auto'
      const height = el.offsetHeight
      el.style.height = null
      tweenParams.to = { height }
    }

    if (heightStart !== tweenParams.to.height) {
      tween(
        Object.assign({
          render: ({ height }) => {
            el.style.height = `${height}px`
          },
          easing: 'easeInOutQuad',
          duration: 500,
          from: {
            height: heightStart
          }
        },
        tweenParams
      )).then(() => {
        if (!el.offsetHeight) {
          el.style.height = null
        }
      })
    }

  }
}

register('studio', StudioSection)
