const mMediaMap = new Map()
const classNamePrefix = 'media-'

export function set(key, mediaQuery) {
	if (typeof key !== 'string' || typeof mediaQuery !== 'string') {
    return
  }
  const mqList = window.matchMedia(mediaQuery)
  const className = `${classNamePrefix}${key.trim()}`
  const mqListener = function (mqList) {
    if (mqList.matches) {
      document.body.classList.add(className)
    }
    else {
      document.body.classList.remove(className)
    }
  }

  mqList.addEventListener('change', mqListener)
  mMediaMap.set(key, {
  	mqList,
    mqListener
  })
  mqListener(mqList)
  return mqList
}

export function get(key) {
	if (typeof key !== 'string') {
		return
	}
	if (mMediaMap.has(key)) {
		return mMediaMap.get(key).mqList
	}
	return null
}

export function remove(key) {
	if (typeof key !== 'string') {
		return
	}
	if (mMediaMap.has(key)) {
		const mMediaItem = mMediaMap.get(key)
		mMediaItem.mqList.removeEventListener('change', mMediaItem.mqListener)
    return mMediaMap.delete(key)
  }
  return false
}

export function test(key) {
	if (typeof key !== 'string') {
		return
	}
	if (mMediaMap.has(key)) {
    return mMediaMap.get(key).mqList.matches
  }
  return false
}
