import Section from '../modules/section'
import { register } from '../modules/sections'
import { on, emit } from '../modules/events'

class MobileMenuSection extends Section {
  initState() {
    return {
      display: false
    }
  }

  init() {
    this.computed(() => this.displayChanged(this.state.display))
    on('mobilemenu:toggle', () => this.toggle())
    on(this.refs.close, 'pointerup', () => this.toggle())
    this.refs.links.forEach((el) => {
      on(el, 'pointerdown', () => {
        if (el.dataset.param) {
          document.body.classList.remove('layout-unscrollable')
          this.toggle()
          setTimeout(() => {
            window.location.hash = `#${el.dataset.param}`
          }, 100)
        }
      })
    })
  }

  toggle() {
    this.state.display = !this.state.display
  }

  displayChanged(value) {
    const displayClass = this.el.dataset.classDisplay
    this.el.classList[value ? 'add' : 'remove'](displayClass)
    document.body.classList[value ? 'add' : 'remove']('layout-unscrollable')
  }
}

register('mobilemenu', MobileMenuSection)
