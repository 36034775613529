import Section from '../modules/section'
import { register } from '../modules/sections'
import { on, off } from '../modules/events'
import Swiper from 'tiny-swiper'
import GLightbox from 'glightbox'
import * as mmedia from '../modules/mmedia'

class ServicesSection extends Section {
  initState() {
    return {}
  }

  init() {
    setTimeout(() => {
      this.sliderSetup()      
    }, 5);
  }

  sliderSetup() {
    let sliders = new Set()

    if (this.refs?.sliders?.length) {
      this.refs.sliders.forEach((el) => {
        const isSmallScreen = mmedia.test('small')
        const imgs = Array.from(el.querySelectorAll('img'))
        const displayNav = isSmallScreen ? imgs.length > 2 : imgs.length > 4
        const slider = new Swiper(
          el,
          {
            slidesPerView: isSmallScreen ? 2 : 4,
            loop: imgs.length > 4 ? true : false,
            spaceBetween: 0,
            mousewheel: true
          }
        )

        sliders.add(slider)

        const tmpItems = []
        const lightboxElements = imgs
          .map((el) => ({
            href: el.dataset.lightboxSrc,
            type: 'image'
          }))
          .filter((item) => {
            if (!item.href) {
              return false
            }
            if (tmpItems.includes(item.href)) {
              return false
            }
            tmpItems.push(item.href)
            return true
          })

        const nextEl = el.querySelector('services-item-nav-next')
        const prevEl = el.querySelector('services-item-nav-prev')

        if (nextEl) {
          if (displayNav) {
            on(nextEl, 'pointerup', () => {
              slider.slideTo(slider.state.index + 1)
            })
          }
          else {
            nextEl.remove()
          }
        }
        if (prevEl) {
          if (displayNav) {
            on(prevEl, 'pointerup', () => {
              slider.slideTo(slider.state.index - 1)
            })
          }
          else {
            prevEl.remove()
          }
        }

        imgs.forEach((el) => {
          let startTs = Date.now()
          on(el, 'pointerdown', () => {
            startTs = Date.now()
          })
          on(el, 'pointerup', () => {
            const diffTs = Date.now() - startTs
            if (diffTs > 90) {
              return
            }
            const index = lightboxElements.reduce((value, item, i) => {
              if (item.href === el.dataset.lightboxSrc) {
                return i
              }
              return value
            }, 0)
            const gallery = GLightbox({
              startAt: index,
              elements: lightboxElements
            })
            gallery.open()
          })
        })
      })

      let timeout
      const eId = on(window, 'resize', function onResize() {
        if (!timeout) {
          timeout = setTimeout(() => {
            off(eId)
            for(const slider of sliders.entries()) {
              sliders.delete(slider[0])
              slider[0].destroy()
            }
            this.sliderSetup()
          }, 1000);
        }
      }.bind(this))
    }
  }
}

register('services', ServicesSection)
