import Section from '../modules/section'
import { register } from '../modules/sections'
import { on } from '../modules/events'
import Swiper from 'tiny-swiper'

class HeroSection extends Section {
  initState() {
    return {
      index: 0
    }
  }
  
  init() {
    this.computed(() => this.indexChanged(this.state.index))
    this.sliderSetup()
  }

  sliderSetup() {
    this.slider = new Swiper(
      this.refs.slider,
      {
        slidesPerView: 1,
        centeredSlides: false,
        loop: true,
        spaceBetween: 1
      }
    )

    let timeout
    const resizeSlider = function() {
      this.slider.update()
    }.bind(this)
    on(window,  'resize', () => {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null
          resizeSlider()
        }, 200)
      }
    })

    this.slider.on('after-slide', () => {
      this.state.index = this.slider.state.index
    })

    this.refs.indexes.forEach((el) => {
      on(el, 'pointerup', () => {
        const index = this.refs.indexes.indexOf(el)
        this.state.index = index
      })
    })
  }

  indexChanged(value) {
    if (this?.slider && value !== this.slider.state.index) {
      this.slider.slideTo(value)
    }

    const activeClass = this.refs.indexesContainer.dataset.activeClass
    this.refs.indexes.forEach((el, i) => {
      if (i === value) {
        el.classList.add(activeClass)
      }
      else {
        el.classList.remove(activeClass)
      }
    })
  }
}

register('hero', HeroSection)
