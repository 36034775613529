import Section from '../modules/section'
import { register } from '../modules/sections'
import { on } from '../modules/events'

class FooterSection extends Section {
  init() {
    on(this.refs.up, 'pointerup', () => {
      window.scrollTo({
        top: 0
      })
    })
  }
}

register('footer', FooterSection)
