const eventsMap = new Map()

export function on() {
	let node
	let name
	let fn
	let params = {}
	if ([3, 4].includes(arguments.length)) {
		node = arguments[0]
		name = arguments[1]
		fn = arguments[2]
		params = arguments[3] ?? {}
	}
	if (arguments.length === 2) {
		node = document
		name = arguments[0]
		fn = arguments[1]
	}
	if (
		typeof name !== 'string' ||
		typeof fn !== 'function' ||
		typeof node?.addEventListener !== 'function'
	) {
		return
	}
	const id = `${Math.floor(Math.random() * 9999)}${Date.now().toString().substr(8)}${Math.floor(Math.random() * 9999)}`
	const eObj = { name, fn, node }
	node.addEventListener(name, fn)
	eventsMap.set(id, eObj)
	return id
}

export function off(id) {
	if (typeof id !== 'string') {
		return
	}
	if (eventsMap.has(id)) {
		const eObj = eventsMap.get(id)
		eObj.node.removeEventListener(eObj.name, eObj.fn)
	}
	return eventsMap.delete(id)
}

export function emit(name, detail = {}, node = document) {
	if (
		typeof name !== 'string' ||
		typeof detail !== 'object' ||
		typeof node?.dispatchEvent !== 'function'
	) {
		return
	}
	node.dispatchEvent(new CustomEvent(name, { 
		bubbles: true,
		cancelable: true,
		detail
	}))
}
